import React, { useCallback, useEffect, useState } from 'react';
import InheritanceInput from "../InheritanceInput";
import { connect } from "react-redux";
import { setInheritanceAmountYou } from "../../../../../store/actions/savingsAndInvestments";

const InheritanceAmountYou = ( props ) => {

    const [value, setValue] = useState(props.inheritanceTotal || 0);
    const [error, setError] = useState(false);
    const errorMessage = 'Value can not be less than $1,000'

    const validation = useCallback((val) => {
            if(val <1000){
                setError(true);
            }else {
                setError(false)
            }
    }, [])

    const onChange = e => {
        const value = +e.target.value.replace(/\$|\s/g, '');
        setValue(value)
        validation(value)
        props.setInheritanceAmountYou(value)
    };

    useEffect(()=> {
        validation(props.inheritanceTotal)
    }, [props, validation]);

    return (
        <InheritanceInput
            title={'Total inheritance'}
            onChange={onChange}
            error={error}
            value={value}
            errorMessage={errorMessage}
        />
    )
};

function mapStateToProps(state) {
    return {
        inheritanceTotal: state.savingsAndInvestments.you.inheritance.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInheritanceAmountYou: (val) => dispatch(setInheritanceAmountYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InheritanceAmountYou);